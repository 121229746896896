<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_partner_clinic") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="columns.name.title" prop="name">
            <crm-input v-model="form.name" autocomplete="off"></crm-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="columns.phone_number.title" prop="phone_number">
            <crm-input
              v-model="form.phone_number"
              :maskFormat="'tel'"
              autocomplete="off"
            ></crm-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            :label="columns.responsible_name.title"
            prop="responsible_name"
          >
            <crm-input
              v-model="form.responsible_name"
              autocomplete="off"
            ></crm-input>
          </el-form-item>
        </el-col>
        <!-- --------------------------- -->

        <el-col :span="4">
          <div class="el-form-item__label w-100 text-left">
            {{$t('message.partner_clinic_full_logo')}}
          </div>
          <div class="imgUpload">
            <el-upload
              :action="base_url + 'clinicImages/storeFullImage'"
              :limit="1"
              accept="image/*"
              name="image"
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewFull"
              :on-remove="handleRemove"
              :on-success="handleAvatarSuccessFull"
              :headers="{
                Authorization: 'Bearer ' + $store.getters.token,
              }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleFull">
              <img width="100%" :src="dialogFullImageUrl" alt />
            </el-dialog>
          </div>
        </el-col>

        <el-col :span="4">
          <span class="el-form-item__label">{{$t('message.partner_clinic_mini_logo')}}</span>
          <div class="imgUpload">
            <el-upload
              :action="base_url + 'clinicImages/storeImage'"
              :limit="1"
              accept="image/*"
              name="image"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="handleAvatarSuccess"
              :headers="{
                Authorization: 'Bearer ' + $store.getters.token,
              }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt />
            </el-dialog>
          </div>
        </el-col>
        <!-- ------------------------------- -->

        <!-- end col -->
        <el-col :span="8">
          <el-radio-group v-model="form.type_blank">
            <el-radio :label="'FULL_LOGO'">{{$t('message.partner_clinic_full_logo_active')}}</el-radio>
            <el-radio :label="'HALF_LOGO'"
              >{{$t('message.partner_clinic_mini_logo_active')}}</el-radio
            >
          </el-radio-group>
        </el-col>

        <el-col :span="8">
          <el-select
            class="w-100"
            size="small"
            v-model="form.departments"
            multiple
            clearable
            filterable
            allow-create
            default-first-option
            :placeholder="$t('message.department')"
          >
            <el-option
              v-for="item in departments"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- end row -->
      <!-- <el-card class="box-card"> -->

      <div class="block mt-4">
        <el-tree
          class="filter-tree"
          :data="data"
          show-checkbox
          node-key="id"
          :filter-node-method="filterNode"
          ref="items"
          @node-click="handleNodeClick"
          :expand-on-click-node="true"
        >
          <span
            :style="`background: ${data.color}`"
            class="custom-tree-node"
            slot-scope="{ node, data }"
          >
            <span class="aaa my-tree-khan">
              <div>{{ data.name }}</div>
              <!--   v-model="data.type"   -->
              <el-select
                :placeholder="$t('message.class')"
                v-model="data.amount_type"
                @change="onSelectType(data, node)"
                :disabled="!node.checked"
                size="small"
                class="my-kh ml-3"
                style=""
              >
                <el-option
                  v-for="item in classes"
                  :key="item.name"
                  :label="item.name"
                  :value="item.type"
                >
                </el-option>
              </el-select>
              <crm-input
                :disabled="!node.checked"
                @change="onSelectAmount(data)"
                v-model="data.amount"
                :placeholder="'sum'"
                ref="'data_amount'+ node.id"
                class="my-kh"
                size="small"
              ></crm-input>
            </span>
          </span>
        </el-tree>
      </div>
      <!-- </el-card> -->
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import form from "@/utils/mixins/form";

export default {
  mixins: [form],

  data() {
    return {
      data: [],
      services: [],
      fileList: [],
      dialogImageUrl: "",
      dialogFullImageUrl: "",
      dialogVisible: false,
      dialogVisibleFull: false,
      drawerPatientService: false,
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "partnerClinic/rules",
      model: "partnerClinic/model",
      departments: "department/list",
      columns: "partnerClinic/columns",
    }),
  },
  mounted() {
    const query = { relation: true };
    this.servicesRealtionList(query)
      .then((res) => {
        this.data = res.data.services;
      })
      .catch((err) => {});
    this.loadDepartment();
  },
  methods: {
    ...mapActions({
      save: "partnerClinic/store",
      loadDepartment: "department/index",
      servicesRealtionList: "services/relationList",
      destroy: "clinicImages/destroy",
    }),
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.services = this.$refs.items.getCheckedNodes();
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.parent().listChanged();
              this.resetForm("form");
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    closeDrawerPatientService(obj) {
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    handleAvatarSuccess(res, file) {
      this.form.clinic_image_id = res.data.clinicImages.id;
    },
    handleAvatarSuccessFull(res, file) {
      this.form.clinic_full_image_id = res.data.clinicImages.id;
    },
    handleAvatarSuccessFile(res, file) {
      this.form.patient_file_id = res.data.patientFiles.id;
    },
    handleRemove(file, fileList) {
      this.destroy(file.response.data.clinicImages.id).then((res) => {});
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureCardPreviewFull(file) {
      this.dialogFullImageUrl = file.url;
      this.dialogVisibleFull = true;
    },
    handlePreview(file) {
      console.log(file);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
    onSelectType(data, node) {
      let parent = null;
      if (data && !data.parent_id) {
        parent = this.data.find((el) => el.id == data.id);
        parent.children.map((child) => {
          child.type = data.type;
        });
      }
    },
    onSelectAmount(data) {
      let parent = null;
      if (data && !data.parent_id) {
        parent = this.data.find((el) => el.id == data.id);
        parent.children.map((child) => {
          child.amount = data.amount;
        });
      }
    },
  },
};
</script>
<style>
.my-kh input,
.my-kh {
  width: 200px !important;
  /* height: 32px  !important;
  line-height: 32px  !important; */
  margin-right: 7px !important;
  padding-right: 7px !important;
  display: block !important;
}
.filter-tree .custom-tree-node {
  width: 96%;
  display: inline-table !important;
}

.filter-tree .el-tree-node__content .aaa {
  display: flex;
}
.filter-tree .el-tree-node__content {
  height: 40px;
}
</style>